<template>
    <div id="mian">
        <div class="header">
            <span>自动结算查询</span>
        </div>
        <!-- 上面表单框 -->
        <div class="con_from">
                <p>
                    <i>商户名称</i>
                    <el-input v-model="input" style="width:19.5%;height:36px" suffix-icon="el-icon-search"	 placeholder="分账商户名称/编号"></el-input>
                    <i class="jianju">结算日期</i>
                    <el-date-picker 
                    style="width:19.5%;height:36px"
                    v-model="value1"
                    type="datetime"
                    placeholder="选择时间">
                    </el-date-picker>
                    -
                    <el-date-picker
                    style="width:19.5%;height:36px"
                    v-model="value2"
                    type="datetime"
                    placeholder="选择时间">
                    </el-date-picker>
                    <el-button type="primary" style="float:right; background:#48B8B6;width:7.5%;height:40px;">查询</el-button>
                </p>

        
               
            </div>
            <!-- 中间交易账目 -->
            <div class="jiaoyi">
                <div class="jiaoyi_box" >
                    <p><i>结算金额</i></p>
                    <p><b>14235</b></p>
                </div>
                <div class="jiaoyi_box">
                    <p><i>分账商户手续费</i></p>
                    <p><b>14235</b></p>
                </div>
                <div class="jiaoyi_box" >
                    <p><i>实际到账金额</i></p>
                    <p><b>14235</b></p>
                </div>
            </div>
        
        <div class="tab1">
            <table class="tab">
                <tr class="tab_title" >
                    <td style="width:60px;padding-left:40px;"><p>结算日期</p></td>
                    <td style="width:150px"><p>分账商户名称</p></td>
                    <td style="width:60px"><p>分账商户编号</p></td>
                    <td style="width:80px"><p>所属商户</p></td>
                    <td style="width:70px"><p>结算金额</p><p>分账商户手续费(元)</p></td>
                    <td style="width:80px"><p>实际到账金额</p></td>
                    <td style="width:80px"><p>结算账户</p><p>结算账户名</p></td>
                    <td><p>操作</p></td>
                </tr>
                <tr>
                    <td style="width:138px;padding-left:15px;"><p>2019-01-12 15:51:15</p> <p>2019-01-12 15:51:59</p></td>
                    <td style="margin-left:15px;"><p>深圳有数互动科技有限公司</p></td>
                    <td><p>12345678901234567</p> <p>0000678901234567</p></td>
                    <td><p>-</p> <p>-</p></td>
                    <td style="width:85px"><p>微信扫码支付</p></td>
                    <td><p>支付成功</p></td>
                    <td><p>支付成功</p></td>
                    <td style="width:66px"><p><i class="lianjie">重新生成</i></p></td>
                </tr>
               
            </table>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            value1:"",
            value2:"",
        }
    },
    methods:{
        //新增按钮
        addclickHander(){
            this.$router.push("/user/businessList_shlb/addcommercial_jbxx")
        }
    }
    
}
</script>
<style scoped>
.lianjie{
    color: #008AFF;
}
.tab tr td p{
    width: 100%;
    text-align: center;
    font-size:14px;
    font-family:Microsoft YaHei;
    font-weight:400;
    color:rgba(102,102,102,1);
}
.tab_title{
    background:rgba(244,245,246,1);
    border-top:1px solid rgba(220,224,230,1); 
}
.tab1 .tab tr{
    padding-left:20px; 
    height: 60px;
    border-bottom:1px solid rgba(220,224,230,1); 
}
.tab{
    border-collapse:collapse; 
    width: 100%;
    background: #fff;
    border-bottom:1px solid rgba(220,224,230,1); 
}
.tab1{
    margin-top:20px; 
}
.jiaoyi_box p b{
    font-size:24px;
    font-family:PingFang SC;
    font-weight:600;
    color:rgba(72,184,182,1);
}
.jiaoyi_box p i{
    font-size:12px;
    font-family:Microsoft YaHei;
    font-weight:400;
    color:rgba(153,153,153,1);
}
.jiaoyi_box p{
    padding-top:18px; 
    width: 100%;
    text-align: center;
}
.jiaoyi{
    width: 100%;
    height: 90px;
    margin-top:20px;
    display: flex;
    justify-content: space-between; 
}
.jiaoyi_box{
    float: left;
    width: 32%;
    height: 90px;
    background:rgba(255,255,255,1);
    box-shadow:0px 2px 9px 0px rgba(0, 0, 0, 0.05);
    border-radius:5px;
}
.jianju{
    
    padding-left:59px; 
}
.con_from p i{
    padding-right:12px; 
    font-size:14px;
    font-family:Microsoft YaHei;
    font-weight:400;
    color:rgba(0,0,0,1);
}
.con_from p{
    padding-top:20px; 
}
.header span{
    padding-right:35px; 
    font-size:18px;
    font-family:Microsoft YaHei;
    font-weight:400;
    color:rgba(51,51,51,1);
}
.header{
    line-height: 60px;
    width: 100%;
    height: 60px;
    border-bottom:1px solid  rgba(220,224,230,1);
}
#mian{
    padding-left:20px;
    padding-right:21px;  
    width: 96.5%;
    height: 100%;
}
</style>